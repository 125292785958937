import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";


@Injectable({ providedIn: 'root' })
export class SystemService {

  constructor() {
  }

  public songPlaying: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public playSong(): void {
    this.songPlaying.next(true);
  }

  public stopSong(): void {
    this.songPlaying.next(false);
  }

  public getSongStatus() {
    return this.songPlaying;
  }
}
