<div class="about-container">
  <div class="pause" (click)="pauseSong()" *ngIf="systemService.songPlaying|async">
    <span class="material-icons pause-icon">
      pause
    </span>
  </div>
  <div class="pause" (click)="playSong()" *ngIf="!(systemService.songPlaying|async)">
    <span class="material-icons pause-icon">
      play_arrow
    </span>
  </div>
  <div class="column right-column">
    <div class="header">
      <div class="photo">
        <img src="./assets/images/profile.png" alt="profile" />
      </div>
      <div class="header-info">
        <div class="top">
          <div class="title">אברהם מאיר בן עליזה</div>
          <div class="info-line">
            נולד ב - י"ג סיוון תשי"ג
            <br>
            נלב"ע ב - א טבת תש"פ
          </div>
          <div class="info-line">
            נטמן בבית העלמין הישן בטבריה
            <br>
            (על יד קבר רחל)
          </div>
        </div>
        <a class="tell-link" (click)="openPopup()">
          <div class="tell-us">
            <div class="icon"><img src="./assets/images/about.svg" alt="tell" /></div>
            <span> ספרו לנו עליו</span>
          </div>
        </a>
      </div>
    </div>
    <div>
      <div class="about-him-title title-holder">קצת עליו</div>
      <div class="about-text">
        איש פשוט שקט טוב ואוהב את הבריות<br>
        האמין בלתת לאחר גם אם נשאר לך מעט והיה מאושר במעט שלו<br>
        את לילותיו חלק עם הספרים והמחברות בבית המדרש<br>
        בשורות שמילאו דפים צהובים בחידושים ופירושים לפי פרשות וספרים חגים ומועדים<br>
        ה קשיב לכל מי שהיה צריך אוזן, יעץ והתפלל עבור כולם<br>
        העביר הלאה כל ידע שהיה ברשותו ולימד סת"ם<br>
        כתב תפילין ומזוזות<br>
        דיבר מעט והשתדל לעשות הרבה. בלי שאף אחד ישים לב
      </div>
      <div class="about-him-title title-holder"> הסיפור של גינת אגוז</div>
      <div class="about-text">
        יום שישי בצהריים, חודשיים בערך אחרי הפטירה של אבא.<br>
        שכבתי במיטה עם כאבי ראש מנסה להירדם - בישיבה.<br>
        נרדמתי, אני חולם - אבא מופיע בחלום, מדבר לכאורה נושא קלאסי שלו - גימטריה.<br>
        אני רואה בחלום מספרים בינאריים (מספרים של אפס ואחד) המספרים מייצגים פרשות שלמות.<br>
        כל מילה מורכבת מאפסים ואחדים, בלי רווחים - ממש כמו תוכנה.<br>
        אני מבין שהוא מדבר איתי על גימטריה - כל התורה היא מספרים זה רץ כמו תוכנה.<br>
        הסתיים החלום, הגיע החלום הבא - חלום סתמי ולא מיוחד עד ששמעתי מישהו קורא לי בקול תקיף בשמי.<br>
        זה מתוך החלום, מישהו משך אותי חזרה אל החלום הקודם.<br>
        הקול שלי או הקול שלו, אני לא בטוח אבל זה העיר אותי.<br>
        קמתי, פתחתי מחשב, אקסל, ויקיפדיה..<br>
        הבנתי שאני לא בכיוון, זה משהו גדול יותר בכיוון של אתר - תוכנה.<br>
      </div>
      <div class="about-him-title title-holder"> הסיפור של השיר - תעיתי כשה אובד</div>
      <div class="about-text">
        כשאבא היה בבית החולים בחודשים האחרונים לפני פטירתו, הוא ביקש בכמה הזדמנויות<br>
        שונות ובכמה פורומים שונים שלנו בביקורים, לשמוע את השיר "תעיתי כשה אובד", הוא מאוד אהב את הגרסה של הזמר ישראל דגן לשיר זה.<br>
        חיפשנו המון את השיר בכל האתרים המוכרים והפחות מוכרים ולא הצלחנו למצוא את הביצוע הנדיר שהוא חיפש.<br>
        לאחר פטירתו כשהתלבטנו מה לרשום על קברו, הבנו שהוא אמר לנו בצורה מדוייקת מה לרשום.<br>
        ולכן, רשמנו על קברו את הפסוק מתהילים פרק קיט פסוק קעו "תעיתי כשה אובד בקש עבדך כי מצוותיך לא שכחתי"<br>
        לאחר מכן בהמשך לחלום ופיתוח האתר, פניתי לזמר ישראל דגן שבמקרה גם הכיר אותו, ביקשתי ממנו את השיר אותו אשים בדף שלו.
        הזמר ישראל דגן העביר לי את השיר כשרגע מתנגן ברקע.<br>
        אנצל את המקום הזה לומר לך תודה ישראל דגן, שימחת אותנו ואת אמא.<br>
      </div>
    </div>
  </div>
  <div class="column left-column">
    <div class="about-him-title">מספרים עליו</div>
    <div class="tell-about-him">
      <div class="tell-title">
        <div class="borders">||</div>
        <span>שמואל קוק</span>
      </div>
      <div class="tell-content">
        שמואל היה בקורס נהיגה מונעת. ביום השני הייתה שם אישה שאחרה ב-5 דקות בגלל הבייביסיטר של הילדים. לא הסכימו לה
        בשום
        פנים ואופן להכנס, גם לא כשהתחננה ממש. ביום השלישי לסיום הנהיגה מונעת היה לשמואל יום עמוס במיוחד עם עומס
        בכביש
        והוא רואה שהוא מאחר. למוד ניסיון מהיום הקודם, רצה כבר לחזור הביתה.. רבע שעה איחור! מה הסיכוי אחרי שאתמול
        אישה
        איחרה ב-5 דקות ולא הסכימו להכניס אותה? על המקןם שמואל הבטיח 500 שח לרבי אברהם. כשהוא נכנס הפקידה צועקת עליו
        וצועקת מה אתה מאחר זה לא בסדר ועוד ועוד ואז צועקת עליו יאללה כנס כבר.
      </div>
    </div>
    <div class="tell-about-him">
      <div class="tell-title">
        <div class="borders">||</div>
        <span>אנונימי</span>
      </div>
      <div class="tell-content">
        הגעתי לרכסים, פגשתי חבר ותיק אשר תינה את צערו בפני. יש לו בת בגיל 24 שעדיין לא התחתנה, יש לבת שלו חברה שגם
        היא
        עוד לא התחתנה ובן שגם כבר הוא צריך להתחתן. הערבתי לרבי אברהם את הבקשות… אחרי מספר חודשים הגעתי שוב לרכסים
        ושאלתי
        את המכר מה המצב. המכר סיפר לי שמחר החתונה של הבן, הבת שלו כבר התחתנה והחברה שלה גם.
      </div>
    </div>
    <div class="tell-about-him">
      <div class="tell-title">
        <div class="borders">||</div>
        <span>אנונימי</span>
      </div>
      <div class="tell-content">
        בחור ישיבה מבוגר בא להתברך לזיווג אצל רבי אברהם (כמובן שהכל בשקט, בלי לגלות מה הוא יכול לעשות ולפעול), רבי
        אברהם
        נתן ל תאריך לחתונה והוא התחתן. אח"כ ביקש שגם יזכה לתינוק, קיבל תאריך. ביקש עוד פעם שאשתו לא תלד בשבת, רבי
        אברהם
        מסכים, בסדר גמור. אשתו ילדה שעה לפני כניסת השבת.
      </div>
    </div>
    <div class="tell-about-him">
      <div class="tell-title">
        <div class="borders">||</div>
        <span>אנונימי</span>
      </div>
      <div class="tell-content">
        סיפרתי לרבי אברהם שלבן שלי יש הצעה ושמה נחמה. הוא מאוד שמח והיה שבע רצון, אמר לקדם את העניין. לבסוף השידוך
        התבטל, השידוך שלאחריו בוטל גם הוא. הצעה שלישית, שוב נחמה, הפעם יש חתונה. סיפרתי לרבי אברהם והוא התנצל, לא
        ידעתי
        שיכול להיות לאדם אחד שתי הצעות מתאימות אשר לשתיהן השם "נחמה".
      </div>
    </div>
    <div class="tell-about-him">
      <div class="tell-title">
        <div class="borders">||</div>
        <span>אנונימי</span>
      </div>
      <div class="tell-content">
        נגנב לי התיק בכניסה לבית המרחץ, חיפשתי בכל האזור הקרוב. בדר"כ בודקים ולוקחים מה שמוצא חן בעיני ה"לוקח". אחרי
        ייאוש וחוסר סבלנות, פניתי לרבי אברהם שהבטיח לי "התיק יחזור לאותו מקום שממנו לקחו אותו". אחרי יומיים, האחראי
        על
        בית המרחץ אומר לי: "זה התיק שלך?", עניתי כן, זה התיק שלי.. הסיכוי שמי שלקח את התיק יחזיר אותו לאותו מקום
        ממנו
        לקח אותו, שואף לאפס, אבל ברכה של רבי אברהם זו ברכה והבטחה זו הבטחה.
      </div>
    </div>
    <div class="tell-about-him">
      <div class="tell-title">
        <div class="borders">||</div>
        <span>אנונימי</span>
      </div>
      <div class="tell-content">
        רבי אברהם מאיר בן עליזה ושלמה נראה בן אדם כמו כולנו, דתי עם זקן ארוך ומלא, אור על הפנים וכולו מלא נתינה
        לזולת.
        הגענו לטבריה, הדירה שלנו מול החדר שרבי אברהם לומד כל הלילה. אשתי לא הייתה רגועה, מקום חדש, רעשים לא מוכרים..
        אמרה לעצמה "אל תדאגי, יש פה יהודי שלומד כל הזמן, אם יהיה צורך אפנה אליו לעזרה". כל פעם שהגעתי אליו והשלום
        בית
        היה צולע, בלי להגיד כלום היה מדבר איתי, היה קולט את המצב ומסביר לי על שלום בית - האישה היא המלכות וכו'
        וכו'...
        קצת מפחיד מה הוא עוד יודע עליי שאני בעצמי לא יודע..
      </div>
    </div>
    <div class="tell-about-him">
      <div class="tell-title">
        <div class="borders">||</div>
        <span>עופר בחבוט</span>
      </div>
      <div class="tell-content">
        אשתי ואני ניסינו להיכנס להריון הרבה זמן, עשינו הפריות ונכנסנו להריון.
        במהלך ההריון הייתה סכנה לעובר.
        פניתי לרבי אברהם, סיפרתי את הסיפור והוא הכין עבורי קמע בכתב ידו ואמר לי תן לאישה ושתענוד אותו עד ללידה.
        לקחתי את הקמע, שמרתי אותו בתוך תרמיל כדור ונתתי לה לענוד.
        ב"ה ההריון עבר בשלום והלידה הייתה מוצלחת.
      </div>
    </div>
    <div class="tell-about-him">
      <div class="tell-title">
        <div class="borders">||</div>
        <span>עופר בחבוט</span>
      </div>
      <div class="tell-content">
        דיברתי פעם הרב קוק, במהלך השיחה הרב אמר לי, אתה יודע שיש לאברהם רוח הקודש.
        תמיד הייתי מתקשר לרבי אברהם בכל שבוע לשאול הלכות ולהתייעץ, באחת השיחת שלנו אמרתי לו תגיד לי אבל באמת, יש לך רוח
        הקודש? הרב קוק אמר שיש לך.
        הוא בתגובה ענה לי, עזוב הרב קוק אומר את זה על כל אחד.
      </div>
    </div>
  </div>
</div>