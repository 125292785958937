import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './home/home.component';
import {ResultsComponent} from './results/results.component';
import {GimatriaComponent} from './gimatria/gimatria.component';
import {NameComponent} from './name/name.component';
import {AboutComponent} from './about/about.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'results/:searchType/:searchStr', component: ResultsComponent},
  {path: 'gimatria/:searchStr', component: GimatriaComponent},
  {path: 'name/:searchStr', component: NameComponent},
  {path: 'about', component: AboutComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
