<div class="home-container">
  <div class="search-section">
    <div class="search-inner-container">
      <h1>גינת אגוז</h1>
      <h2>על חידודי תורה, גימטריה וצירופי קבלה</h2>
      <form (ngSubmit)="search()">
        <div class="form-container">
          <div class="main-search">
            <input type="text" [(ngModel)]="searchStr" name="searchStr"
                   placeholder="חיפוש מילה או מחרוזת מילים"/>
          </div>
          <div class="search-options">
            <div class="option" (click)="selectSearchOption('all')">
              <div class="is-selected" [ngClass]="{'selected': searchOption === 'all'}">
                <div class="selected-value"><span class="material-icons">done</span></div>
              </div>
              <div class="option-name">חיפוש על כל התורה</div>
            </div>
            <div class="option" (click)="selectSearchOption('parasha')">
              <div class="is-selected" [ngClass]="{'selected': searchOption === 'parasha'}">
                <div class="selected-value"><span class="material-icons">done</span></div>
              </div>
              <div class="option-name">חיפוש על פרשת השבוע</div>
            </div>
            <div class="option" (click)="selectSearchOption('mikra')">
              <div class="is-selected" [ngClass]="{'selected': searchOption === 'mikra'}">
                <div class="selected-value"><span class="material-icons">done</span></div>
              </div>
              <div class="option-name">חיפוש על מקטע מהמקרא</div>
            </div>
          </div>
          <div class="search-submit custom-btn" (click)="search()">צא ולמד</div>
        </div>
      </form>
    </div>
  </div>
  <div class="find-name">
    <div class="header">הפסוק שלי בתורה</div>
    <div class="content-and-search">
      <div class="content">
        "בעת פטירתו של אדם שואלים אותו איך קראו לו. והוא שוכח את שמו, דבר שגורם לו ייסורים רבים וקשים. סגולה שלא ישכח את
        שמו ליום הדין יאמר בחייו בכל יום בסוף תפילת שמונה עשרה לפני הפסוק: 'יהיו לרצון…' שבסיום, פסוק אחד שמתחיל באות
        הראשונה של שמו ומסתיים באות האחרונה. ואם מצא פסוק שיש בו אזכור שמו מה טוב, ואין צורך באותיות ראשונה ואחרונה"
      </div>
      <div class="search-name">
        <div class="search-name-box"><input type="text" [(ngModel)]="findName" placeholder="השם שלך"/></div>
        <div class="search-name-btn" (click)="searchName()">מצא את שמי</div>
      </div>
    </div>
  </div>
</div>
