import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit{

  searchStr: string;
  searchType: string;
  searchInputText: string;
  section: string = "search";

  constructor(private route: ActivatedRoute,
              private router: Router){

  }

  ngOnInit(): void{
    this.searchStr = this.route.snapshot.paramMap.get('searchStr');
    this.searchType = this.route.snapshot.paramMap.get('searchType');
    this.searchInputText = this.searchStr;
  }

  search(){
    this.searchStr = this.searchInputText;
    this.router.navigateByUrl(`/results/${this.searchType}/${this.searchStr}`);
  }

  selectSection(section: string){
    this.section = section;
  }

  goHome(){
    this.router.navigateByUrl('');
  }

}
