<div class="results-container">
  <div class="header">
    <div class="logo"><img src="./assets/images/logo.svg" alt="גינת אגוז" (click)="goHome()" /></div>
    <form (ngSubmit)="search()">
      <div class="form-container">
        <input type="text" [(ngModel)]="searchInputText" name="search-str" />
        <div class="submit-search custom-btn" (click)="search()">צא ולמד</div>
      </div>
    </form>
  </div>
  <div class="sections-holder">
    <div class="sections">
      <div class="section" [ngClass]="{'selected': section === 'search'}" (click)="selectSection('search')">חיפוש מילה
      </div>
      <div class="section" [ngClass]="{'selected': section === 'initials'}" (click)="selectSection('initials')">ראשי
        תיבות
      </div>
      <div class="section" [ngClass]="{'selected': section === 'jumps'}" (click)="selectSection('jumps')">קפיצות מילים
      </div>
    </div>
    <div class="black-line"></div>
  </div>

  <div class="section-separator"></div>
  <div class="results">
    <div class="result-box jumps" *ngIf="section === 'jumps'">
      <jumps [searchStr]="searchStr"></jumps>
    </div>
    <div class="result-box initials" *ngIf="section === 'initials'">
      <initials [searchStr]="searchStr"></initials>
    </div>
    <div class="result-box search" *ngIf="section === 'search'">
      <words [searchStr]="searchStr"></words>
    </div>
  </div>
</div>