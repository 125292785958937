import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import tora from '../../assets/data.json';
import lettersData from '../../assets/letters.json';

@Component({
  selector: 'name',
  templateUrl: './name.component.html',
  styleUrls: ['./name.component.scss']
})
export class NameComponent{

  searchStr = '';
  searchStrInput = '';
  results = [];

  constructor(private route: ActivatedRoute,
              private router: Router){

  }

  ngOnInit(): void{
    this.searchStr = this.route.snapshot.paramMap.get('searchStr');
    this.searchStrInput = this.searchStr;
    this.searchName();
  }

  updateResults(){
    this.router.navigateByUrl(`/name/${this.searchStrInput}`);
    this.searchStr = this.searchStrInput;
    this.searchName();
  }

  goHome(){
    this.router.navigateByUrl(``);
  }

  customTransform(sentence: string){
    let transformed = '';
    let letters = lettersData.letters;
    for (let i = 0; i < sentence.length; i++){
      if (sentence[i] == ' ' || sentence[i] == '.' || sentence[i] == ',' || sentence[i] == '-' || Object.keys(letters).includes(sentence[i])){
        transformed += sentence[i];
      }
    }
    return transformed;
  }


  searchName(){
    this.results = [];
    let firstLetter = this.searchStr[0];
    let lassLetter = this.searchStr[this.searchStr.length-1];
    tora.forEach(book => {
      book.parashot.forEach(parasha => {
        parasha.prakim.forEach(perek => {
          perek.sentences.forEach(sentence => {
            let sentenceData = {book, parasha, perek, sentence};
            let sentenceValue = this.customTransform(sentence.value);
            if(sentenceValue[0] === firstLetter && sentenceValue[sentenceValue.length-1] === lassLetter){
              this.results.push(sentenceData);
            }
          });
        });
      });
    });
  }

  getLocation(result){
    return "ספר " + result.book.value + " פרשת " + result.parasha.value + " פרק " + result.perek.value + " (פסוק " + result.sentence.id + ")";
  }

  getResultStr(result){
    return result.sentence.value;
  }
}
