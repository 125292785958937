<div class="name-container">
  <div class="header">
    <div class="explanation">
      <div class="title">
        <div class="back" (click)="goHome()"><img src="./assets/images/arrow-back.svg" alt="back" /></div>
        <div class="text">השם שלך בתורה</div>
      </div>
      <div class="explanation-content">
        "בעת פטירתו של אדם שואלים אותו איך קראו לו. והוא שוכח את שמו, דבר שגורם לו ייסורים רבים וקשים. סגולה שלא ישכח את
        שמו ליום הדין יאמר בחייו בכל יום בסוף תפילת שמונה עשרה לפני הפסוק: 'יהיו לרצון…' שבסיום, פסוק אחד שמתחיל באות
        הראשונה של שמו ומסתיים באות האחרונה. ואם מצא פסוק שיש בו אזכור שמו מה טוב, ואין צורך באותיות ראשונה ואחרונה"
      </div>

    </div>
    <div class="search-tora">
      <input type="text" [(ngModel)]="searchStrInput" placeholder="חיפוש מילה או מחרוזת" />
      <div class="go-and-learn" (click)="updateResults()">צא ולמד</div>
    </div>
  </div>
  <div class="seperator"></div>

  <div class="results">
    <div class="results-boxes">
      <div class="result-box" *ngFor="let result of results">
        <div class="location">{{getLocation(result)}}</div>
        <div class="result">
          <!-- <div class="first-letter">{{result.sentence.value[0]}}</div>
          <div class="sentence">{{result.sentence.value.substring(1,result.sentence.value.length-1)}}</div>
          <div class="last-letter">{{result.sentence.value[result.sentence.value.length-1]}}</div> -->
          <div class="all-sentence">
            <p><b>
                {{result.sentence.value[0]}}</b>{{result.sentence.value.substring(1,result.sentence.value.length-1)}}
              <b>{{result.sentence.value[result.sentence.value.length-1]}}</b></p>
          </div>
        </div>
      </div>
      <div class="no-results" *ngIf="!results.length">
        <div class="empty-state">
          <img class="empty-state-image" src="./assets/images/empty-state.png" alt="empty-state-image">
          אוי, לחיפוש הזה אין לנו תוצאות שאפשר להציג :(</div>
      </div>
    </div>
  </div>

</div>