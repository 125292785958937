import {Component, Input, SimpleChanges} from '@angular/core';
import tora from '../../../assets/data.json';

@Component({
  selector: 'initials',
  templateUrl: './initials.component.html',
  styleUrls: ['./initials.component.scss']
})
export class InitialsComponent{

  @Input() searchStr: string;
  wordJumps: number = 1;
  lockSearch: boolean = false;
  results: Array<{ words: string, data: Array<any> }>;

  constructor(){
  }

  runSearch(){
    this.wordJumps = Math.floor(this.wordJumps);
    if (!this.lockSearch){
      this.lockSearch = true;
      setTimeout(() => {
        this.lockSearch = false;
      }, 500);
      let parasha = tora[0].parashot;
      let firstWordsIndexes = [];
      tora.forEach((book, bookIndex) => {
        book.parashot.forEach((parasha, parashaIndex) => {
          parasha.prakim.forEach((perek, perekIndex) => {
            perek.sentences.forEach((sentence, sentenceIndex) => {
              let pasuk = sentence.value;
              let pasukWords = pasuk.split(' ');
              for (let wordIndex = 0; wordIndex < pasukWords.length; wordIndex++){
                if (pasukWords[wordIndex][0] === this.searchStr[0]){
                  let letterData = {bookIndex, parashaIndex, perekIndex, sentenceIndex, wordIndex};
                  firstWordsIndexes.push({words: pasukWords[wordIndex] + ' ', data: [letterData]});
                }
              }
            });
          });
        });
      });
      for (let i = 1; i < this.searchStr.length; i++){
        let currentLetter = this.searchStr[i];
        let newfirstWordsIndexes = [];
        for (let j = 0; j < firstWordsIndexes.length; j++){
          let nextWord;
          let currentData = firstWordsIndexes[j].data[firstWordsIndexes[j].data.length - 1];
          let words = firstWordsIndexes[j].words;
          let bookIndex = currentData.bookIndex;
          let book = tora[bookIndex];
          let parashaIndex = currentData.parashaIndex;
          let parasha = book.parashot[parashaIndex];
          let perekIndex = currentData.perekIndex;
          let perek = parasha.prakim[perekIndex];
          let sentenceIndex = currentData.sentenceIndex;
          let sentence = perek.sentences[sentenceIndex].value;
          let pasukWords = sentence.split(' ');
          let wordIndex = currentData.wordIndex + this.wordJumps;
          if (wordIndex < pasukWords.length){
            nextWord = pasukWords[wordIndex];
            // Connect the words
            for (let k = currentData.wordIndex + 1; k <= wordIndex; k++){
              words += pasukWords[k] + ' ';
            }
          }
          else{
            // Connect the words
            for (let k = currentData.wordIndex + 1; k < pasukWords.length; k++){
              words += pasukWords[k] + ' ';
            }
            let skippedWords = pasukWords.length - 1 - currentData.wordIndex;
            let foundNextWord = false;
            while (!foundNextWord){
              sentenceIndex++;
              if (perek.sentences.length <= sentenceIndex){
                // Go to next perek
                sentenceIndex = 0;
                if (parasha.prakim.length > perekIndex + 1){
                  perek = parasha.prakim[perekIndex + 1];
                }
                else{
                  // Go to next parasha
                  perekIndex = 0;
                  if (book.parashot.length > parashaIndex + 1){
                    parashaIndex++;
                    parasha = book.parashot[parashaIndex];
                  }
                  else{
                    // Go to next book
                    parashaIndex = 0;
                    if (tora.length > bookIndex + 1){
                      book = tora[bookIndex + 1];
                    }
                    else{
                      break;
                    }
                  }
                }
              }
              sentence = perek.sentences[sentenceIndex].value;
              let pasukWords = sentence.split(' ');
              wordIndex = this.wordJumps - skippedWords - 1;
              if (wordIndex >= pasukWords.length){
                skippedWords += pasukWords.length;
                // Connect the words
                words += sentence + ' ';
              }
              else{
                nextWord = pasukWords[wordIndex];
                foundNextWord = true;
                // Connect the words
                for (let k = 0; k <= wordIndex; k++){
                  words += pasukWords[k] + ' ';
                }
              }
            }
          }

          // Check if the word is ok
          if (nextWord && nextWord[0] === currentLetter){
            let letterData = {bookIndex, parashaIndex, perekIndex, sentenceIndex, wordIndex};
            let newData = firstWordsIndexes[j].data;
            newData.push(letterData);
            newfirstWordsIndexes.push({words, data: newData});
          }
        }

        firstWordsIndexes = newfirstWordsIndexes;
      }
      this.results = firstWordsIndexes;
    }
  }

  getLocationString(info){
    let start = info.data[0];
    let resStr = 'ספר ' + tora[start.bookIndex].value + ' פרשת ' + tora[start.bookIndex].parashot[start.parashaIndex].value + ' פרק ' + tora[start.bookIndex].parashot[start.parashaIndex].prakim[start.perekIndex].value + ' פסוק ' + tora[start.bookIndex].parashot[start.parashaIndex].prakim[start.perekIndex].sentences[start.sentenceIndex].id;
    if(info.data.length > 1){
      let end = info.data[info.data.length - 1];
      resStr += ' - ספר ' + tora[end.bookIndex].value + ' פרשת ' + tora[end.bookIndex].parashot[end.parashaIndex].value + ' פרק ' + tora[end.bookIndex].parashot[end.parashaIndex].prakim[end.perekIndex].value + ' פסוק ' + tora[end.bookIndex].parashot[end.parashaIndex].prakim[end.perekIndex].sentences[end.sentenceIndex].id;
    }
    return resStr;
  }

  breakWords(words){
    return words.split(" ");
  }

  ngOnChanges(changes: SimpleChanges){
    this.runSearch();
  }

}
