import {Component, Input, SimpleChanges} from '@angular/core';
import tora from '../../../assets/data.json';
import lettersData from '../../../assets/letters.json';

@Component({
  selector: 'words',
  templateUrl: './words.component.html',
  styleUrls: ['./words.component.scss']
})
export class WordsComponent{

  @Input() searchStr: string;
  results: Array<{ book: string, parashot: Array<{ parasha: string, sentences: Array<{ id: string, value: string, numberOfTimes: number }>, parashaOpen: boolean }>, bookOpen: boolean }>;

  constructor(){
  }

  getNumberOfContains(sentence: string, checkContains: string){
    let contains = 0;
    if (checkContains.length > sentence.length){
      return contains;
    }
    for (let i = 0; i <= sentence.length - checkContains.length; i++){
      let matches = true;
      for (let j = 0; j < checkContains.length; j++){
        if (sentence[i + j] != checkContains[j]){
          matches = false;
          break;
        }
      }
      if (matches){
        contains++;
      }
    }
    return contains;
  }

  customTransform(word: string){
    let transformed = '';
    let letters = lettersData.letters;
    for (let i = 0; i < word.length; i++){
      if (word[i] == ' ' || word[i] == '.' || word[i] == ',' || word[i] == '-' || Object.keys(letters).includes(word[i])){
        transformed += word[i];
      }
    }
    return transformed;
  }

  runSearch(){
    this.results = [];
    tora.forEach(book => {
      let bookData = {book: book.value, parashot: [], bookOpen: false};
      book.parashot.forEach(parasha => {
        let parashaData = {parasha: parasha.value, sentences: [], parashaOpen: false};
        parasha.prakim.forEach(perek => {
          perek.sentences.forEach(sentence => {
            let numberOfContains = this.getNumberOfContains(this.customTransform(sentence.value), this.searchStr);
            if (numberOfContains > 0){
              let sentenceData = {...sentence};
              sentenceData.numberOfTimes = numberOfContains;
              parashaData.sentences.push(sentenceData);
            }
          });
        });
        if (parashaData.sentences.length){
          bookData.parashot.push(parashaData);
        }
      });
      if (bookData.parashot.length){
        this.results.push(bookData);
      }
    });
    console.log(this.results);
  }

  ngOnChanges(changes: SimpleChanges){
    this.runSearch();
  }

  getNumberOfTimes(){
    let sum = 0;
    if (this.results?.length){
      this.results.forEach(result => {
        sum += this.getNumberOfTimesInBook(result);
      });
    }
    return sum;
  }

  getNumberOfTimesInBook(bookData){
    let sum = 0;
    bookData.parashot.forEach(parashaResult => {
      sum += this.getNumberOfTimesInParasha(parashaResult);
    })
    return sum;
  }

  toggleReadBook(bookData){
    bookData.bookOpen = !bookData.bookOpen;
  }

  getNumberOfTimesInParasha(parashaData){
    let sum = 0;
    parashaData.sentences.forEach(sentenceData => {
      sum += sentenceData.numberOfTimes;
    });
    return sum;
  }

  toggleReadParasha(parashaData){
    parashaData.parashaOpen = !parashaData.parashaOpen;
  }
}
