<div class="hidudim">
  <div class="hidudim-content">
    <div class="title">חידודים</div>
    <div class="example">{{value}} = {{calcSentenceSemiGimatria()}} בגימטרייה</div>
    <div class="line">גימטרייה - כל אות מיוצגת כמספר, למשל האות א מיוצגת כמספר 1</div>
    <div class="example"><br>{{value}} = {{calcSentenceFullGimatria()}} בגימטרייה מלאה</div>
    <div class="line">גימטרייה מלאה - כל אות מורכבת ממספר אותיות, למשל האות א נשמעת "אלף" - מורכבת משלוש אותיות והערך הגימטרי שלה הוא 111 גימטרייה מלאה תאפשר לך לחשב את
      הערך הגימטרי המלא של המילה / המחרוזת שהזנת
    </div>
  </div>
  <div class="search-gimatria">
    <input type="text" [(ngModel)]="searchStr" (keyup.enter)="search()" placeholder="משפטים בתורה עם אותו ערך גימטרי"
      value={{value}} />
    <div class="search-arrow" (click)="search()"><img src="./assets/images/arrow-brown-left.svg" alt="<"></div>
  </div>
</div>