import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  searchOption = 'all';
  searchStr: string;
  findName = "";

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  selectSearchOption(option: string){
    this.searchOption = option;
  }

  search(){
    if(this.searchStr && this.searchStr.length){
      this.router.navigateByUrl(`/results/${this.searchOption}/${this.searchStr}`);
    }
  }

  searchName(){
    if(this.findName && this.findName.length){
      this.router.navigateByUrl(`/name/${this.findName}`);
    }
  }

}
