import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {Howl} from 'howler';
import {SystemService} from './shared/services/system.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent{

  audio;

  constructor(private router: Router,
              private systemService: SystemService){
    this.systemService.getSongStatus().subscribe(res => {
      if(this.audio){
       if(res){
         this.playSong();
       }
       else{
         this.stopSong();
       }
      }
    })
  }

  ngOnInit(){
    this.audio = new Audio();
    this.audio.src = './assets/bg-song.mpeg';
    this.audio.currentTime = 53;
    this.audio.load();
  }

  playSong(){
    this.audio.currentTime = 53;
    this.audio.play();
  }

  linkToAbout(){
    this.playSong();
    this.router.navigateByUrl('about');
  }

  stopSong(){
    this.audio.pause();
    this.audio.currentTime = 0;
  }

}
