import { Component, ElementRef, HostListener, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContactUsDialogComponent } from '../contact-us-dialog/contact-us-dialog.component';
import { SystemService } from '../shared/services/system.service';

@Component({
  selector: 'about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent {
  display = true;
  constructor(public systemService: SystemService,
    private dialog: MatDialog,
    private elemRef: ElementRef) {
  }

  ngOnInit() {
    this.elemRef.nativeElement.click();
    this.systemService.playSong();
  }

  ngOnDestroy() {
    this.systemService.stopSong();
  }

  pauseSong() {
    this.systemService.stopSong();
  }

  playSong() {
    this.systemService.playSong();
  }

  openPopup() {
    const dialogRef = this.dialog.open(ContactUsDialogComponent, {
      width: 'auto',
      height: 'auto'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}
