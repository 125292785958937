import {Component, Input} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import lettersData from '../../../assets/letters.json';

@Component({
  selector: 'hidudim',
  templateUrl: './hidudim.component.html',
  styleUrls: ['./hidudim.component.scss']
})
export class HidudimComponent{


  @Input("value") value: string;
  searchStr = "";

  constructor(private router: Router){

  }

  calcSentenceSemiGimatria(){
    let res = 0;
    for (let i = 0; i < this.value?.length; i++){
      if (lettersData.letters[this.value[i]]){
        res += lettersData.letters[this.value[i]];
      }
    }
    return res;
  }

  calcSentenceFullGimatria(){
    let res = 0;
    for (let i = 0; i < this.value?.length; i++){
      if (lettersData.fullLetters[this.value[i]])
        res += lettersData.fullLetters[this.value[i]];
    }
    return res;
  }


  search(){
    if(this.searchStr.length){
      this.router.navigateByUrl(`/gimatria/${this.searchStr}`);
    }
  }

}
