import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import {MatIconModule} from '@angular/material/icon';
import { ResultsComponent } from './results/results.component';
import {FormsModule} from '@angular/forms';
import {JumpsComponent} from './results/jumps/jumps.component';
import {InitialsComponent} from './results/initials/initials.component';
import {WordsComponent} from './results/words/words.component';
import {HidudimComponent} from './results/hidudim/hidudim.component';
import {GimatriaComponent} from './gimatria/gimatria.component';
import {NameComponent} from './name/name.component';
import {AboutComponent} from './about/about.component';
import {MatDialogModule} from '@angular/material/dialog';
import {DialogModule} from 'primeng/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { ContactUsDialogComponent } from './contact-us-dialog/contact-us-dialog.component';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ResultsComponent,
    JumpsComponent,
    InitialsComponent,
    WordsComponent,
    HidudimComponent,
    GimatriaComponent,
    NameComponent,
    AboutComponent,
    ContactUsDialogComponent
  ],
  imports: [
    BrowserAnimationsModule,
    MatIconModule,
    DialogModule,
    MatDialogModule,
    AppRoutingModule,
    FormsModule
  ],
  providers: [],
  entryComponents: [
    ContactUsDialogComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
