<head>
    <script src="https://code.iconify.design/1/1.0.7/iconify.min.js"></script>
</head>

<div class="container">
    <div class="title">
        <h1>ספרו לנו עליו</h1>
        <div class="border-line"></div>
    </div>

    <div class="contact-options">
        <a class="link-button" href="mailto:usdrawing@gmail.com" target="_blank">
            <span class="material-icons">email</span></a>

        <a class="link-button" href="tel:5551234567Call (555)123-4567">
            <span class="material-icons">phone</span></a>

        <a class="link-button" href="https://api.whatsapp.com/send/?phone=%2B972502237058&text&app_absent=0"
            target="_blank">
            <span class="material-icons"><img src="../assets/images/whatsapp.svg" alt="whatsapp" /></span></a>
    </div>
    <div class="info-line">
        <p>.אנחנו נשמח לקבל סיפורים, תמונות וכל מידע נוסף</p>
        <p>במייל, בווטסאפ ובטלפון</p>
    </div>
</div>