<div class="words-container">
  <div class="words-data">
    <div class="title">איפה מופיע "{{searchStr}}" בתורה?</div>
    <div class="number-of-times">{{searchStr}} = מופיע {{getNumberOfTimes()}} פעמים בתורה</div>
    <div class="data-container">
      <div class="book-info" *ngFor="let result of results">
        <div class="book-title" (click)="toggleReadBook(result)">
          <div class="book-name">ספר {{result.book}}: {{getNumberOfTimesInBook(result)}}</div>
          <div class="toggle-read" *ngIf="!result.bookOpen">
            <div class="read">קרא עוד</div>
            <div class="toggle"><img src="./assets/images/arrow-down-white.svg" alt=">" /></div>
          </div>
          <div class="toggle-read" *ngIf="result.bookOpen">
            <div class="read">קרא פחות</div>
            <div class="toggle"><img src="./assets/images/arrow-up-white.svg" alt=">" /></div>
          </div>
        </div>
        <div class="book-data" *ngIf="result.bookOpen">
          <div class="parasha-info" *ngFor="let parashaResult of result.parashot">
            <div class="parasha-title" (click)="toggleReadParasha(parashaResult)">
              <div class="parasha-name">פרשת {{parashaResult.parasha}}: {{getNumberOfTimesInParasha(parashaResult)}}
              </div>
              <div class="toggle-parasha-read" *ngIf="!parashaResult.parashaOpen">
                <div class="read">קרא עוד</div>
                <div class="toggle"><img src="./assets/images/arrow-down-brown.svg" alt=">" /></div>
              </div>
              <div class="toggle-parasha-read" *ngIf="parashaResult.parashaOpen">
                <div class="read">קרא פחות</div>
                <div class="toggle"><img src="./assets/images/arrow-down-brown.svg" class="rotate" alt=">" /></div>
              </div>
            </div>
            <div class="parasha-data" *ngIf="parashaResult.parashaOpen">
              <div class="sentence" *ngFor="let pasuk of parashaResult.sentences">
                {{pasuk.value}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="no-results" *ngIf="!results.length">
        <div class="empty-state">
          <img class="empty-state-image" src="./assets/images/empty-state.png" alt="empty-state-image">
          אוי, לחיפוש הזה אין לנו תוצאות שאפשר להציג :(</div>
      </div>
    </div>
  </div>
  <hidudim [value]="searchStr"></hidudim>
</div>