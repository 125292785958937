import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-contact-us-dialog',
  templateUrl: './contact-us-dialog.component.html',
  styleUrls: ['./contact-us-dialog.component.scss']
})
export class ContactUsDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ContactUsDialogComponent>, @Inject(MAT_DIALOG_DATA) data: any) { }


  ngOnInit(): void {

  }

  // onNoClick(): void {
  //   this.dialogRef.close();
  // }
}
