<div class="jumps-container">
  <div class="jumps-data">
    <div class="title">
      <span>ראשי תיבות של המילה / המחרוזת שהוזנה בקפיצות של:</span>
      <input type="number" min="1" step="1" [(ngModel)]="wordJumps" (keyup)="runSearch()"
        (ngModelChange)="runSearch()" />
      <span class="margin-top-mobile">מילים</span>
    </div>
    <div class="psukim-container">
      <div class="pasuk-box" *ngFor="let result of results">
        <div class="location">{{getLocationString(result)}}</div>
        <div class="content">
          <div class="word" *ngFor="let word of breakWords(result.words); index as i"
            [ngClass]="{'jump-word': i%wordJumps == 0}">
            {{word}}
          </div>
        </div>
      </div>
      <div class="no-results" *ngIf="!results.length">
        <div class="empty-state">
          <img class="empty-state-image" src="./assets/images/empty-state.png" alt="empty-state-image">
          <div>אוי, לחיפוש הזה אין לנו תוצאות שאפשר להציג :(</div>
        </div>
      </div>
    </div>
  </div>
  <hidudim [value]="searchStr"></hidudim>
</div>