import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import tora from '../../assets/data.json';
import lettersData from '../../assets/letters.json';

@Component({
  selector: 'gimatria',
  templateUrl: './gimatria.component.html',
  styleUrls: ['./gimatria.component.scss']
})
export class GimatriaComponent{

  searchStr = '';
  searchStrInput = '';
  results = {
    semi: [],
    full: []
  };

  constructor(private route: ActivatedRoute,
              private router: Router){

  }

  ngOnInit(): void{
    this.searchStr = this.route.snapshot.paramMap.get('searchStr');
    this.searchStrInput = this.searchStr;
    this.searchGimatria();
  }

  updateResults(){
    this.router.navigateByUrl(`/gimatria/${this.searchStrInput}`);
    this.searchStr = this.searchStrInput;
    this.searchGimatria();
  }

  goHome(){
    this.router.navigateByUrl(``);
  }

  customTransform(sentence: string){
    let transformed = '';
    let letters = lettersData.letters;
    for (let i = 0; i < sentence.length; i++){
      if (sentence[i] == ' ' || sentence[i] == '.' || sentence[i] == ',' || sentence[i] == '-' || Object.keys(letters).includes(sentence[i])){
        transformed += sentence[i];
      }
    }
    return transformed;
  }

  calcSentenceSemiGimatria(sentence: string){
    let res = 0;
    for (let i = 0; i < sentence.length; i++){
      if (lettersData.letters[sentence[i]]){
        res += lettersData.letters[sentence[i]];
      }
    }
    return res;
  }

  calcSentenceFullGimatria(sentence: string){
    let res = 0;
    for (let i = 0; i < sentence.length; i++){
      if (lettersData.fullLetters[sentence[i]])
        res += lettersData.fullLetters[sentence[i]];
    }
    return res;
  }

  searchGimatria(){
    this.results = {semi: [], full: []};
    let mySearchSemi = this.calcSentenceSemiGimatria(this.searchStr);
    let mySearchFull = this.calcSentenceFullGimatria(this.searchStr);
    tora.forEach(book => {
      book.parashot.forEach(parasha => {
        parasha.prakim.forEach(perek => {
          perek.sentences.forEach(sentence => {
            let sentenceData = {book, parasha, perek, sentence};
            let sentenceValue = this.customTransform(sentence.value);
            if (this.calcSentenceSemiGimatria(sentenceValue) == mySearchSemi){
              this.results.semi.push({...sentenceData, value: mySearchSemi});
            }
            if (this.calcSentenceFullGimatria(sentenceValue) == mySearchFull){
              this.results.semi.push({...sentenceData, value: mySearchFull});
            }
          });
        });
      });
    });
  }

  getLocation(result){
    return "ספר " + result.book.value + " פרשת " + result.parasha.value + " פרק " + result.perek.value + " (פסוק " + result.sentence.id + ")";
  }

  getResultStr(result){
    return result.sentence.value + " = " + result.value;
  }
}
