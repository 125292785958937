<div class="gimatria-container">
  <div class="header-container">
    <div class="header">
      <div class="explanation">
        <div class="title">
          <div class="back" (click)="goHome()"><img src="./assets/images/arrow-back.svg" alt="back"/></div>
          <div class="text">פסוקים עם אותו ערך גימטרי</div>
        </div>
        <div class="explanation-content">
          <div class="example">{{searchStr}} = {{calcSentenceSemiGimatria(searchStr)}} בגימטרייה</div>
          <div class="example">{{searchStr}} = {{calcSentenceFullGimatria(searchStr)}} בגימטרייה מלאה</div>
          <div class="line">גימטרייה - כל אות מיוצגת כמספר, למשל האות א מיוצגת כמספר 1</div>
          <div class="line">גימטרייה מלאה - כל אות מורכבת ממספר אותיות, למשל האות א נשמעת "אלף" גימטרייה מלאה תאפשר לך
            לחשב את
            הערך הגימטרי המלא של המילה / המחרוזת שהזנת
          </div>
        </div>

      </div>
      <div class="search-tora">
        <input type="text" [(ngModel)]="searchStrInput" placeholder="חיפוש מילה או מחרוזת"/>
        <div class="go-and-learn" (click)="updateResults()">צא ולמד</div>
      </div>
    </div>
    <div class="seperator"></div>
  </div>

  <div class="results">
    <div class="results-section semi">
      <div class="title">גימטרייה</div>
      <div class="results-boxes">
        <div class="result-box" *ngFor="let result of results.semi">
          <div class="location">{{getLocation(result)}}</div>
          <div class="result">{{getResultStr(result)}}</div>
        </div>
        <div class="no-results" *ngIf="!results.semi.length">אוי, לחיפוש הזה אין לנו תוצאות שאפשר להציג :(</div>
      </div>
    </div>
    <div class="results-section full">
      <div class="title">גימטרייה מלאה</div>
      <div class="results-boxes">
        <div class="result-box" *ngFor="let result of results.full">
          <div class="location">{{getLocation(result)}}</div>
          <div class="result">{{getResultStr(result)}}</div>
        </div>
      </div>
      <div class="no-results" *ngIf="!results.full.length">אוי, לחיפוש הזה אין לנו תוצאות שאפשר להציג :(</div>
    </div>
  </div>

</div>
